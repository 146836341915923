import React from 'react';
import { GlobalStyle } from '../styles/global';
import { Container, Card, H2, H3B, P, BottomBanner, WhiteBtn } from '../styles/styles';
import SEO from '../components/seo';
import Header from '../components/Header';
import Features from '../components/Features';
import Footer from '../components/Footer';

const features = () => {
  return (
    <React.Fragment>
      <SEO title="Features" />
      <GlobalStyle />
      <Header />
      <Features background="#fff" />
      <Container style={{ padding: '1.2em', marginTop: '25px' }}>
        <Card>
          <div style={{ marginBottom: '16px' }}>
            <H3B>Cloud-based Storage</H3B>
          </div>
          <P>Create one place for all design assets, centrally located in the cloud. such as Files like logos, photos, icons, illustrations, images colors, gradients and more. Design Lobby supports JPG, PNG, GIF, SVG, WEBP, TTF, OTF, WOFF, and WOFF2 font files.</P>
        </Card>
        <br />
        <Card>
          <div style={{ marginBottom: '16px' }}>
            <H3B>Structure and Organization</H3B>
          </div>
          <P>Build folder and collections for specific asset types, projects, brand guideline</P>
        </Card>
        <br />
        <Card>
          <div style={{ marginBottom: '16px' }}>
            <H3B>Version Control</H3B>
          </div>
          <P>Keep track of all changes in assets with versioning</P>
        </Card>
        <br />
        <Card>
          <div style={{ marginBottom: '16px' }}>
            <H3B>Works with Figma - Coming Soon</H3B>
          </div>
          <P>Use Design Lobby for your Figma file our Figma Plugin. </P>
        </Card>
        <BottomBanner>
          <H2 style={{ padding: '1em' }}>
            Get <span style={{ fontWeight: '700' }}>Design Lobby</span> Early Access <br />
            or Want to know about product updates?
          </H2>

          <P style={{ padding: '0 0.5em 0.5em 0.5em ' }}>Design Lobby helps you manage your digital design assets. Built for high-performance design teams and freelancers, Try Design Lobby Beta for Free - No credit card needed</P>
          <br />
          <WhiteBtn>
            <a href="/signup" className="big-card-subtitle">
              Signup for Free
            </a>
          </WhiteBtn>
        </BottomBanner>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default features;
